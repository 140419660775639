import React from "react";
import {Form, Input, Select} from "antd";
import language from "../../../data/language.yaml";
import countries from "../../../data/countries";

class StepTwo extends React.Component {
    constructor(props) {
        super(props);
        this.props.setForm(2, this.props.form);
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <div>
                <h2>{language.mapBuyOnline.stepTwo.personalDetails[this.props.locale]}</h2>
                <Form.Item>
                    {
                        getFieldDecorator(
                            'forename',
                            {
                                initialValue: this.props.personal.forename,
                                onChange: this.props.changePersonal,
                                rules: [
                                    {
                                        type:     "string",
                                        message:  language.mapBuyOnline.stepTwo.form.firstName.error[this.props.locale],
                                        required: true
                                    }
                                ]
                            })(
                            <Input placeholder={language.mapBuyOnline.stepTwo.form.firstName[this.props.locale]} name="forename"/>
                        )
                    }
                </Form.Item>
                <Form.Item>
                    {
                        getFieldDecorator(
                            'surname',
                            {
                                initialValue: this.props.personal.surname,
                                onChange: this.props.changePersonal,
                                rules: [
                                    {
                                        type:     "string",
                                        message:  language.mapBuyOnline.stepTwo.form.lastName.error[this.props.locale],
                                        required: true
                                    }
                                ]
                            })(
                            <Input placeholder={language.mapBuyOnline.stepTwo.form.lastName[this.props.locale]} name="surname"/>
                        )
                    }
                </Form.Item>
                <Form.Item>
                    {
                        getFieldDecorator(
                            'email',
                            {
                                initialValue: this.props.personal.email,
                                onChange: this.props.changePersonal,
                                rules: [
                                    {
                                        type:     "string",
                                        message:  language.mapBuyOnline.stepTwo.form.emailAddress.error[this.props.locale],
                                        required: true
                                    }
                                ]
                            })(
                            <Input placeholder={language.mapBuyOnline.stepTwo.form.emailAddress[this.props.locale]} name="email"/>
                        )
                    }
                </Form.Item>

                <h2>{language.mapBuyOnline.stepTwo.address[this.props.locale]}</h2>
                <Form.Item>
                    {
                        getFieldDecorator(
                            'address_line_1',
                            {
                                initialValue: this.props.personal.address_line_1,
                                onChange: this.props.changePersonal,
                                rules: [
                                    {
                                        type:     "string",
                                        message:  language.mapBuyOnline.stepTwo.form.firstAddress.error[this.props.locale],
                                        required: true
                                    }
                                ]
                            })(
                            <Input placeholder={language.mapBuyOnline.stepTwo.form.firstAddress[this.props.locale]} name="address_line_1"/>
                        )
                    }
                </Form.Item>
                <Form.Item>
                    {
                        getFieldDecorator(
                            'address_line_2',
                            {
                                initialValue: this.props.personal.address_line_2,
                                onChange: this.props.changePersonal
                            })(
                            <Input placeholder={language.mapBuyOnline.stepTwo.form.secondAddress[this.props.locale]} name="address_line_2"/>
                        )
                    }
                </Form.Item>
                <Form.Item>
                    {
                        getFieldDecorator(
                            'address_city',
                            {
                                initialValue: this.props.personal.address_city,
                                onChange: this.props.changePersonal,
                                rules: [
                                    {
                                        type:     "string",
                                        message:  language.mapBuyOnline.stepTwo.form.addressCity.error[this.props.locale],
                                        required: true
                                    }
                                ]
                            })(
                            <Input placeholder={language.mapBuyOnline.stepTwo.form.addressCity[this.props.locale]} name="address_city"/>
                        )
                    }
                </Form.Item>
                <Form.Item>
                    {
                        getFieldDecorator(
                            'address_postcode',
                            {
                                initialValue: this.props.personal.address_postcode,
                                onChange: this.props.changePersonal,
                                rules: [
                                    {
                                        type:     "string",
                                        message:  language.mapBuyOnline.stepTwo.form.postcode.error[this.props.locale],
                                        required: true
                                    }
                                ]
                            })(
                            <Input placeholder={language.mapBuyOnline.stepTwo.form.postcode[this.props.locale]} name="address_postcode"/>
                        )
                    }
                </Form.Item>
                <Form.Item>
                    {
                        getFieldDecorator(
                            'address_country',
                            {
                                initialValue: this.props.personal.address_country,
                                onChange: this.props.changeCountry,
                                rules: [
                                    {
                                        type:     "string",
                                        message:  language.mapBuyOnline.stepTwo.form.country.error[this.props.locale],
                                        required: true
                                    }
                                ]
                            })(
                                <Select placeholder="" onChange={this.onChangeCountry} dropdownAlign={{ offset:[0,0] }}>
                                    {Object.keys(countries).map((code, index) => (
                                        <Select.Option key={code} value={code}>{countries[code]}</Select.Option>
                                    ))}
                                </Select>,
                            )
                    }
                </Form.Item>
            </div>
        );
    }
}

const StepTwoForm = Form.create()(StepTwo);

export default StepTwoForm;
