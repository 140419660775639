import React from "react";
import {Form} from "antd";
import {CardElement, injectStripe} from 'react-stripe-elements';
import language from "../../../data/language.yaml";

/*
*   For test card numbers etc see https://stripe.com/docs/testing#international-cards
*/
class StepFive extends React.Component {
    passUpData = (cardElement) => {
        this.props.ready(cardElement, this.props.elements, this.props.stripe_key, this.props.stripe)
    }

    render() {
        // Styles for the Stripe elements have to be applied like this.
        // See https://stripe.com/docs/js/appendix/style for style options available
        let style       = {
            base:    {
                iconColor:           '#AECC53',
                color:               '#000',
                fontWeight:          500,
                fontFamily:          'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize:            '16px',
                fontSmoothing:       'antialiased',
                ':-webkit-autofill': {
                    color: '#AECC53',
                },
                '::placeholder':     {
                    color: '#AECC53',
                },
            },
            invalid: {
                iconColor: '#FFC7EE',
                color:     '#FFC7EE',
            },
        }
        let cardElement = null;
        if (this.props.errors.length === 0) {
            cardElement = <CardElement
                hidePostalCode={true}
                onChange={this.props.changeCardElement}
                onReady={this.passUpData}
                style={style}
            />
        }

        return (
            <div>
                <Form.Item>
                    <label>
                        {language.mapBuyOnline.stepFive.cardDetails[this.props.locale]}
                        {cardElement}
                    </label>
                </Form.Item>
            </div>
        );
    }
}

export default injectStripe(StepFive);
