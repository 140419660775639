import React from "react";
import {Checkbox, Form} from "antd";
import language from "../../../data/language.yaml";

class StepThree extends React.Component {
    constructor(props) {
        super(props);
        this.props.setForm(3, this.props.form);
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <div>
                <Form.Item>
                    {
                        getFieldDecorator(
                            'agree_terms',
                            {
                                initialValue:      this.props.agreements.agree_terms,
                                onChange:          this.props.changeAgreements,
                                rules: [
                                    {
                                        type:     "boolean",
                                        message:  language.mapBuyOnline.stepThree.agreeTerms.error[this.props.locale],
                                        required: true,
                                        transform: value => (value || undefined)
                                    }
                                ],
                                valuePropName: "checked"
                            }
                        )(
                            <Checkbox name="agree_terms"><span dangerouslySetInnerHTML={{__html: language.mapBuyOnline.stepThree.agreeTerms[this.props.locale]}}/></Checkbox>
                        )
                    }
                </Form.Item>
                <Form.Item>
                    {
                        getFieldDecorator(
                            'agree_privacy',
                            {
                                initialValue:      this.props.agreements.agree_privacy,
                                onChange:          this.props.changeAgreements,
                                rules: [
                                    {
                                        type:     "boolean",
                                        message:  language.mapBuyOnline.stepThree.agreePrivacy.error[this.props.locale],
                                        required: true,
                                        transform: value => (value || undefined)
                                    }
                                ],
                                valuePropName: "checked"
                            }
                        )(
                            <Checkbox name="agree_privacy"><span dangerouslySetInnerHTML={{__html: language.mapBuyOnline.stepThree.agreePrivacy[this.props.locale]}}/></Checkbox>
                        )
                    }
                </Form.Item>
            </div>
        );
    }
}

const StepThreeForm = Form.create()(StepThree);

export default StepThreeForm;
