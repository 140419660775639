import React from "react";
import {Card, Col} from "antd";
import language from "../../../data/language.yaml";

class CheckoutSummary extends React.Component {
    row(item, index) {
        let quantity = item.quantity ? (<div>{language.mapBuyOnline.checkoutSummary.qty[this.props.locale]} { item.quantity} </div>) : "";
        return (
            <tr key={"checkoutItem" + index}>
                <td>{ item.name }</td>
                <td>{quantity}&pound;{ (item.price / 100).toFixed(2) } {item.suffix}</td>
            </tr>
        )
    }

    render() {
        return (
            <Col className="checkout-summary" xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}}>
                <Card bordered={false}>
                    <table>
                        <thead>
                        <tr>
                            <th>{language.mapBuyOnline.checkoutSummary[this.props.locale]}</th>
                            <th>&pound;{(this.props.total / 100).toFixed(2)}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.props.items.map(function (item, index) {
                            return this.row(item, index);
                        }, this)}
                        </tbody>
                    </table>
                </Card>
            </Col>
        );
    }
}

export default CheckoutSummary;
